<template>
  <section>
    <GlobalEvents @keydown.alt.78="keymap" />
    <b-sidebar
      type="is-light"
      :fullheight="true"
      :overlay="true"
      :right="true"
      v-model="open"
      class="sidebarRight newQuoteSidebar"
    >
      <div class="topCloseSideBar is-flex pr-5 p-2">
        <a @click="open = false" class="is-flex color-8">
          <IconChevronLeft class="icon is-small mr-2" />
          <span class="color-4 has-text-weight-medium">New Quote</span>
        </a>
        <a @click="open = false" class="color-8 sideBarCloseButton">
          <IconClose class="icon is-small" />
        </a>
      </div>
      <div class="p-5 SideBarBox">
        <p>Congratulations! Select one of the options to start creating it.</p>
        <div :class="focusedGuideBoxClass('newQuoteSidebarDiv mt-5 p-3', 2)">
          <div class="columns">
            <div class="column">
              <b-navbar-item
                tag="router-link"
                :to="{ path: constants.paths.BASIC_INFO.path }"
                style="padding: 0"
                :data-test-id="
                  constants.DATA_TEST_ID_VALUES.NEW_QUOTE_BUTTON_SCRATCH
                "
              >
                <div
                  class="newQuoteScratch"
                  style="width: 100%"
                  @click="nextStep()"
                >
                  SCRATCH
                </div>
              </b-navbar-item>
            </div>
            <div class="column">
              <a href="javascript:void(0);"
                ><div class="newQuoteModule newQuoteDisabled">MODULE</div></a
              >
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-navbar-item
                tag="router-link"
                :to="{ path: constants.paths.ITINERARIES.path }"
              >
                <div class="newQuoteExItinerary">
                  {{ constants.paths.ITINERARIES.name.toUpperCase() }}
                </div>
              </b-navbar-item>
            </div>
          </div>
        </div>
      </div>
    </b-sidebar>
  </section>
</template>

<script>
import GlobalEvents from "vue-global-events";
import { mapGetters } from "vuex";
import { PATH_INFO } from "@/router/path";
import { DATA_TEST_ID_VALUES } from "@/constants/test";

export default {
  name: "NewQuoteSideBar",
  components: {
    GlobalEvents,
  },
  data() {
    return {
      open: false,
      overlay: true,
      fullheight: true,
      fullwidth: false,
      right: false,
      constants: {
        paths: { ...PATH_INFO },
        DATA_TEST_ID_VALUES,
      },
    };
  },
  watch: {
    isShowSideBar(newValue) {
      this.open = newValue;
    },
    open(newValue) {
      this.setShowSideBar(newValue);
    },
  },
  computed: {
    ...mapGetters({
      isUserGuideActive: "userguide/getIsUserGuideActive",
      userGuideStep: "userguide/getStep",
      isShowSideBar: "utils/isShowSideBar",
    }),
  },
  beforeDestroy() {
    this.setShowSideBar(false);
  },
  methods: {
    keymap(e) {
      e = e || window.event;
      const code = e.which || e.keyCode;
      switch (code) {
        case 78:
          e.preventDefault();
          e.stopPropagation();
          break;
      }
      this.open = !this.open;
    },
    setShowSideBar(value) {
      this.$store.dispatch("utils/setShowSideBar", value);
    },
    resetCurrentItinerary() {
      this.$store.dispatch("itineraryDays/resetItineraryDays");
      this.$store.dispatch("itinerary/resetCurrentItinerary");
      this.$store.dispatch("itinerary/resetPaxPrices");
      this.$store.dispatch("assets/resetAssetsData");
    },
    nextStep() {
      this.resetCurrentItinerary();
      this.setShowSideBar(false);
      if (this.isUserGuideActive) this.$store.dispatch("userguide/nextStep");
    },
    focusedGuideBoxClass(classDefault, step) {
      return this.isUserGuideActive && this.userGuideStep === step
        ? `${classDefault} is-guide-step`
        : classDefault;
    },
  },
};
</script>
<style>
.newQuoteExItinerary {
  width: 100%;
}
</style>
